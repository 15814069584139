import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

export default function E_commerce() {
  const { id } = useParams();

  const [blogData, setBlogData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://laravelreact.psofts.online/api/get-blog-data");
        setBlogData(response.data);
        console.log(response.data);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, []); // Empty dependency array to run the effect only once
  
  console.log('id', id);
  const title = blogData?.blogs?.find((blog) => blog.id === Number(id))?.title;
  const text = blogData?.blogs?.find((blog) => blog.id === Number(id))?.text;
  const image = blogData?.blogs?.find((blog) => blog.id === Number(id))?.image;
  useEffect(() => {
    document.title = title;
  })
  return (
    <div>
      <section class="about_section service layout_padding">
        <div class="container">
          {blogData != null ?
            <div class="row justify-content-center align-item-center text-justify">
              <div class="heading_container col-md-9">
                <h2>{title}</h2>
              </div>
              <div class="col-md-9">
                <div class="img-box sblog-img">
                  <img src={`https://laravelreact.psofts.online/${image}`} alt="website image" />
                </div>
              </div>
              <div class="col-md-9">
                {/* {blogData && blogData.blogs && blogData.blogs.map((blog) => (
                <div class="detail-box" key={blog.id}>
                  <div class="heading_container">
                    <h2>{blog.title}</h2>
                  </div>
                  <p>{blog.text}</p>
                  <a href="" data-toggle="modal" data-target="#quote">
                    Get Free Consultation
                  </a>
                </div>
              ))} */}

                <div class="detail-box" key={id}>
                  <div dangerouslySetInnerHTML={{ __html: text }} />
                  <a href="#" data-toggle="modal" data-target={"#quote"}>
                    Get Free Consultation
                  </a>
                </div>

              </div>
            </div>
            : <img src="images/preloader.gif" className="d-flex justify-content-center align-items-center m-5" alt="preloader" />}
        </div>
      </section>
    </div>
  );
}
