import axios from "axios";
import React, { useState, useEffect } from "react";

export default function BLogs() {
  const formatter = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "long",
    day: "2-digit"
  });
  const [blogData, setBlogData] = useState(null);
  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await axios.get(
          "https://laravelreact.psofts.online/api/get-blog-data"
        );
        setBlogData(response.data);
        console.log(blogData);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };
    fetch();
  });
  let Cont = {};
  let Pre = {};
  if (blogData == null) {

    Cont = {
      display: 'none'
    };
  }

  if (blogData != null) {

    Pre = {
      display: 'none'
    };
  }
  return (
    <div>
      {/* <!-- Blog 4 - Bootstrap Brain Component --> */}
      <section class="py-3 py-md-5 py-xl-8">
        <div class="headText">
          <h1>Blogs</h1>
        </div>

        <div class="container overflow-hidden">
          <div className="d-flex justify-content-center align-items-center">
          <img src="images/preloader.gif" alt="preloader" style={Pre} className="m-5" loading="lazy"/>
          </div>
          <div class="row gy-4 gy-lg-0 p-5" style={Cont}>
            {blogData &&
              blogData.blogs.map((blog) => (
                <div class="col-12 col-lg-4 mt-5">
                  <article>
                    <div class="card border-0 cardB">
                      <figure class="card-img-top m-0 overflow-hidden bsb-overlay-hover">
                        <a href={`/blogs/${blog.title.replace(/\s+/g, "-").toLowerCase()}/${blog.id}`}>
                          <img class="img-fluid bsb-scale bsb-hover-scale-up blog-img" loading="lazy" src={blog.image != null ? `https://laravelreact.psofts.online/${blog.image}` : `../images/s4.png`} alt="Business" />
                        </a>
                      </figure>
                      <div class="card-body border bg-white p-4">
                        <div class="entry-header mb-3">
                          <ul class="entry-meta list-unstyled d-flex mb-2">
                            <li>
                              <a class="link-success text-success text-decoration-none" href={`/blogs/${blog.title.replace(/\s+/g, "-").toLowerCase()}/${blog.id}`}>Tech</a>
                            </li>
                          </ul>
                          <h2 class="card-title entry-title h4 mb-0">
                            <a class="link-dark text-decoration-none text-success BlogText" href={`/blogs/${blog.title.replace(/\s+/g, "-").toLowerCase()}/${blog.id}`}>{blog.title}</a>
                          </h2>
                        </div>
                        <p class="card-text entry-summary text-secondary mb-3">
                        </p>
                        <a href={`/blogs/${blog.title.replace(/\s+/g, "-").toLowerCase()}/${blog.id}`} class="btn btn-success m-0 text-nowrap entry-more">Read More</a>
                      </div>
                      <div class="card-footer border border-top-0 bg-light p-4">
                        <ul class="entry-meta list-unstyled d-flex align-items-center m-0">
                          <li>
                            <a class="fs-7 link-secondary text-decoration-none d-flex align-items-center" href={`/blogs/${blog.title.replace(/\s+/g, "-").toLowerCase()}/${blog.id}`}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-calendar3 text-success" viewBox="0 0 16 16">
                                <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                                <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                              </svg>
                              <span class="ms-2 fs-7 text-success">{'\u00A0'}{'\u00A0'}{formatter.format(new Date(blog.created_at))}
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </article>
                </div>
              ))}

          </div>
        </div>
      </section>
    </div>
  );
}
